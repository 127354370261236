.form {

  &__row {

  }

  &__submit {
    margin-top: 1rem;
  }

  &__api-message {
    font-size: 1rem;
    line-height: 1.15;
    color: #f00;
    margin-top: 2rem;

    &_success {
      color: #50b49b;
    }
  }
}
