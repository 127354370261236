.posr {
    position: relative;
}

.dib {
    display: inline-block;
}

.overflow-hidden {
    overflow: hidden !important;
}

.text-red {
    color: $red;
}

.text-green {
    color: $olive;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mb10 {
  margin-bottom: 10rem;
}

.mt2 {
  margin-top: 2rem;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
}

.centered {
    text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.w100p {
    width: 100%;
}
