
            @import "src/assets/scss/vars/_base.scss";
          
.user-menu {
  position: relative;

  @media (max-width: $lg) {
    position: absolute;
    top: 24px;
    right: 32px;
  }

  @media (max-width: $sm) {
    right: 0;
  }

  &__opener {
    width: 3.1rem;
    height: 3.1rem;
    background-color: #fff;
    border-radius: 1.55rem;
    border: 0;
    padding: 0;
    color: #707070;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: transform .1s ease-in-out, box-shadow .1s ease-in-out;

    &:hover {
      transform: translate(-.1rem, -.1rem);
      box-shadow: .1rem .1rem 0 0 rgba(#fff, .5);
    }

    &_active {
      transform: translate(-.1rem, -.1rem);
      box-shadow: .1rem .1rem 0 0 rgba(#fff, .5);
    }
  }

  &__navigation {
    position: absolute;
    z-index: 1;
    top: 3.1rem;
    right: 0;
    opacity: 0;
    transform: translate(0, 20px) scale(.6);
    transition: opacity .15s ease, transform .15s cubic-bezier(0.075, 0.82, 0.165, 1);

    &_shown {
      opacity: 1;
      transform: translate(0) scale(1);
    }
  }

  &__item-box {
    background-color: #fff;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: .05rem 0 1rem .1rem rgba(#000, .1);
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  &__link {
    display: block;
    white-space: nowrap;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: color .2s ease, background-size .2s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: #f0f0f0;
      color: #000;
    }
  }

  &__avatar  {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: .15rem solid #fff;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__default-avatar {
    width: 2.5rem;
    height: 2.5em;
  }
}
