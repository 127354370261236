
            @import "src/assets/scss/vars/_base.scss";
          
.faq-form {
  &__item {
    position: relative;
    padding-bottom: 1.6rem;
    margin-left: 1em;
    margin-right: 1em;

    &:after { // group background
      content: '';
      position: absolute;
      z-index: -1;
      top: -1.2rem;
      left: -1.2rem;
      right: -1.2rem;
      bottom: 2.2rem;
      border-radius: .5rem;
      background-color: #fff;
      border: 1px solid rgba(#000, .075);
      box-shadow: 0 .15rem .3rem rgba(#000, .1);
    }

    &_error:after { // group background
      border-color: rgba(#f00, .2);
      box-shadow: 0 .15rem .3rem rgba(#000, .1), 0 0 0 .4rem rgba(#f00, .2);
    }
  }

  &__title-box {
    display: flex;
    flex-direction: row;
  }

  &__input {
    flex-grow: 1;
  }

  &__expand-button {
    position: absolute;
    top: 0;
    right: 7rem;
    padding: 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, 0);
    border: 0;
  }

  &__expand-icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform .2s ease;
    transform: rotateZ(-180deg);

    &_active {
      transform: rotateZ(-90deg);
    }

    svg {
      fill: none;
      stroke: #000;
    }
  }

  &__delete-button {
    width: 6.2rem;
    flex: none;
    margin-left: 0.4rem;
    height: 4rem;
    color: #414141;
    font-size: 1.2rem;
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: .5rem;
  }

  &__body {
    transition: opacity .35s ease, transform .35s ease-in-out;
    opacity: 0;
    transform: translateY(.5rem);
    margin-bottom: 1.6rem;

    &_expanded {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__add-button {
    text-align: left;
    padding-left: 1.3rem !important;
    padding-right: 1.3em !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__add-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}
