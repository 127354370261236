.layout {
  &__element {
    transition: opacity .2s easy;

    &_reloading {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  &__description {
    font-size: 1.4rem;
    line-height: 1.14286;
    padding-bottom: 2rem;
    text-align: left;
    width: 100%;

    @media (max-width: $lg) {
      text-align: center;
    }

    &_step-form {
      font-weight: 700;
      padding-bottom: 0.5rem;
    }
  }

  &__link {
    &_strong {
      font-weight: 700;
    }

    &_green {
      color: #50b49b;
      text-decoration: underline;
    }
  }

  &__hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e1e1e1;
    margin-bottom: 4rem;
  }

  &__sign-up {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    position: relative;

    @media (max-width: $lg) {
      position: static;
      padding-top: 4rem;
    }

    &_average-form {
      padding-top: 14rem;

      @media (max-width: $lg) {
        padding-top: 4rem;
      }
    }

    &_small-form {
      padding-top: 16rem;

      @media (max-width: $lg) {
        padding-top: 4rem;
      }
    }

    &_big-form {
      padding-top: 6rem;

      @media (max-width: $lg) {
        padding-top: 4rem;
      }
    }
  }

  &__sign-up-title {
    font-size: 2.2rem;
    line-height: 1.13636;
    margin-bottom: 4rem;

    @media (max-width: $lg) {
      text-align: center;
      padding: 0 16px 2rem;
    }

    &_alone {
      margin: 10rem 0 7rem;

      @media (max-width: $lg) {
        margin: 2rem 0 4rem;
      }
    }
  }

  &__sign-up-text {
    font-size: 1.5rem;
    line-height: 1.3333;
    font-weight: 700;

    @media (max-width: $lg) {
      text-align: center;
    }
  }

  &__sign-up-form {
    margin-top: 2.5rem;

    &_small {
      margin-top: 6rem;
    }
  }

  &__step-form {
    padding: 1rem 0;
  }

  &__note {
    color: #969696;
    line-height: 1.35;
  }

  &__nobr {
    white-space: nowrap;
  }

  &__white-link {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }

    &_underline {
      text-decoration: underline;
    }
  }

  &__roku-code {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.25em;
    text-shadow: .05rem .05rem .15rem rgba(#000, .6);
  }

  &__roku-description {
    color: #fff;
    text-shadow: .05rem .05rem .15rem rgba(#000, .6);
  }

  &__card {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 5px 14px 6px 0 rgba(#000, .16);
    border-radius: .5rem;
    padding: 4rem 6rem;
  }

  &__body-centered {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
