
            @import "src/assets/scss/vars/_base.scss";
          
.page-frame {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 3.5rem 0;
  min-height: calc(100vh - 7rem);

  @media (max-width: $lg) {
    flex-direction: column-reverse;
    max-width: 369px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 5rem;
  }

  &__body {
    flex-grow: 1;
    width: 5rem;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: $lg) {
      padding-right: 0;
      width: 100%;
    }
  }

  &__image-box {
    position: relative;
    padding-left: 7.5rem;
    display: flex;
    flex-direction: row;
    padding-top: 14rem;

    @media (max-width: $lg) {
      padding-top: 0;
      padding-left: 0;
      flex-direction: column;

    }

    &:before { // separator line
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #e1e1e1;

      @media (max-width: $lg) {
        display: none;
      }
    }
  }

  &__image {
    width: 43.75rem;
    height: 28.35rem;
    border-radius: 9px;
    box-shadow: 5px 14px 6px 0 rgba(#000, .16);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-color: #000;

    @media (max-width: $lg) {
      height: 21rem;
      width: 100%;
    }

    &_tv-screen {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      padding-left: 3.8rem;
      padding-right: 3.8rem;

      @media (max-width: $lg) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &::before { // background
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #000000 0%, rgba(128, 128, 128, 0) 100%);
      }
    }
  }

  &__content-box {
    width: 43.75rem;
  }

  &__description {
    position: absolute;
    top: 2rem;
    left: 7.5rem;
    background-color: #EB3E3E;
    padding: 0.85rem 1.3rem;
    color: #fff;
    line-height: 1.45;
    font-weight: bold;

    @media (max-width: $lg) {
      position: static;
      border-radius: 9px;
      margin-bottom: 1rem;
    }
  }

  &__screen-data {
    position: relative;
    z-index: 2;
    width: 100px;
    flex-grow: 1;
  }
}
