
            @import "src/assets/scss/vars/_base.scss";
          
.app-toasts {
  position: fixed;
  z-index: 1000;
  bottom: 6rem;
  right: 2.4rem;

  &__message-box {
    
  }

  &__message {
    border: 1px solid rgba(#000, .2);
    border-radius: .8rem;
    padding: 1rem 1rem 1rem 2rem;
    transform-origin: center center;
    transform: perspective(300px) rotateX(0);
    width: 28rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $olive;
    color: #fff;
    opacity: 0;
    transition: opacity .25s ease;
    margin-bottom: 0.75rem;

    &_shown {
      opacity: 1;
    }

    &_error {
      background-color: $red-light;
    }

    &_neutral {
      background-color: #f5f3ed;
      color: $text-light;
    }

    &_has-button {
      position: relative;
      padding-bottom: 4rem;
      align-items: flex-start;
    }
  }

  &__text {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    width: 100px;
    flex-grow: 1;
    padding-right: 1rem;
  }

  &__closer {
    flex: none;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: .4rem;
    background-color: darken($olive, 8%);
    color: #fff;
    transition: background-color .2s ease;

    &:hover {
      background-color: darken($olive, 12%);

      .app-toasts__message_error & {
        background-color: darken($red-light, 14%);
      }

      .app-toasts__message_neutral & {
        background-color: darken(#f5f3ed, 14%);
      }
    }

    .app-toasts__message_error & {
      background-color: darken($red-light, 10%);
    }

    .app-toasts__message_neutral & {
      background-color: darken(#f5f3ed, 10%);
      color: $text-light;
    }
  }

  &__close-icon {
    width: 1.6rem;
    height: 1.6rem;
    transform: rotateZ(45deg);
  }

  &__action-button {
    position: absolute;
    bottom: 0.6rem;
    left: 1.6rem;
    text-transform: uppercase;
    color: $blue;
    font-family: 'Arimo', Arial, Helvetica, sans-serif;
    height: 3rem;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    font-weight: 700;
    font-size: 1.2rem;
  }
}
