$text: #000;
$text-light: #5C6266;

$olive: #51B997;
$blue: #0A88DD;

$red: #D81F26;
$red-light: #E34953;

$form-control-background: #F9F9F9;
