
            @import "src/assets/scss/vars/_base.scss";
          
.toggle-control {
  width: 3.55rem;
  height: 1.5rem;
  background-color: #fff;
  border: 1px solid #DDDDDD;
  border-radius: .45rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  padding: .1rem;
  cursor: pointer;

  &__input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
  }

  &__switcher {
    flex: none;
    width: 1.35rem;
    height: 1.3rem;
    border-radius: .65rem;
    background-color: #f48e8e;
    transition: background-color .2s ease, transform .2s cubic-bezier(0.075, 0.82, 0.165, 1);

    .toggle-control__input:checked ~ & {
      background-color: #51b997;
      transform: translateX(1.88rem);
    }
  }
}
