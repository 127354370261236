.container {
    width: 100%;
    max-width: 84rem;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;

    &_lg {
      max-width: 94rem;
    }
}
