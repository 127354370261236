
            @import "src/assets/scss/vars/_base.scss";
          
.roku-code {
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  margin-top: -.3rem;

  &__symbol {
    width: 2rem;
    height: 2.6rem;
    padding-top: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, .4);
    text-align: center;
    border-bottom: 1px solid rgba(#fff, .8);

    &:not(:last-of-type) {
      margin-right: 0.3rem;
    }

    // &:nth-of-type(3n + 3) {
    //   margin-right: 0.6rem;
    // }
  }
}
