
            @import "src/assets/scss/vars/_base.scss";
          
.legal-link {
  @media (max-width: $lg) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__link {
    font-size: 1rem;
    line-height: 1.2;

    &:not(:last-of-type) {
      margin-right: 2.4rem;
    }
  }
}
