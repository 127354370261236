
            @import "src/assets/scss/vars/_base.scss";
          
.screen-data {
  &__title {
    font-family: 'Oswald', 'Arimo', Arial, Helvetica, sans-serif;
    font-size: 2.65rem;
    line-height: 1.1;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0.45rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: -0.03em;

    @media (max-width: $lg) {
      font-size: 1.6rem;
    }
  }

  &__description {
    padding: 0;
    margin: 0 0 2.295rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4615;

    @media (max-width: $lg) {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  &__button-box {
    display: flex;
    flex-direction: row;
  }

  &__button {
    pointer-events: none;
    background-color: #fff;
    font-size: .72rem;
    width: 12rem;
    height: 1.6rem;
    border-radius: .25rem;
    border: 0;
    font-weight: 700;

    @media (max-width: $lg) {
      width: 8rem;
    }

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  &__image-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2.45rem;
    padding-right: 2.45rem;
    background: linear-gradient(180deg, rgba(#000, .4), rgba(#000, 0));
  }

  &__media-preview {
    position: absolute;
    top: 6rem;
    right: 2rem;
    width: 10rem;
    height: 16.35rem;
    border-radius: .7rem;

    @media (max-width: $lg) {
      top: 2rem;
      right: .5rem;
    }
  }

  &__background-loader {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__loader-text {
    font-size: 1.2rem;
    padding: 0.05rem 0.75rem 0 0;
    font-weight: 500;
    color: $text-light;

    &_media {
      padding: 0.25rem .25rem 0;
    }
  }

  &__loader-spinner {
    
  }

  &__media-loader {
    background-color: rgba(#fafafa, 80%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__media-message {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
