﻿:root {
    --scrollbar-width: 17px;
}

html,
body {
  font-size: 20px;

  @media (max-width: 1920px) {
    font-size: 12px;
  }

  @media(max-width: 1800px) {
    font-size: 12px;
  }
}

body {
    position: relative;
    min-width: 375px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arimo', Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: $main-text-color;
    overflow-y: scroll;
    background-color: $main-bg;
    transition: background-color .35s ease;
    scroll-behavior: smooth;

    &.modal-shown {
        overflow: hidden;
        padding-right: var(--scrollbar-width);
    }
}

a,
button {
    outline: none !important;
    cursor: pointer;

    &:focus {
        outline: none !important;
    }
}

ul {
    padding-left: 0;
}

li {
    list-style-type: none;
}
