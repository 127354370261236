
            @import "src/assets/scss/vars/_base.scss";
          
.input-control {
    margin-bottom: .6rem;
    padding-bottom: 2.4rem;

    &__box {
        position: relative;
    }

    &__input {
        display: block;
        width: 100%;
        height: 4rem;
        padding: 1.8rem 1.2rem .5rem;
        background-color: $form-control-background;
        border: 0;
        border-radius: 6px 6px 0 0;
        font-family: 'Arimo', Arial, Helvetica, sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
        transition: background-color 0.2s, color .2s;
        -moz-appearance: textfield;
        appearance: textfield;

        @media (max-width: $lg) {
          font-size: 16px;
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            cursor: not-allowed;
            border-color: rgba(#707578, 0);
            color: #707578;
        }

        .input-control_error & {
            background-color: #FEF6F6;
            border-color: $red;
        }

        &::-webkit-contacts-auto-fill-button,
        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            height: 0;
            width: 0;
            margin: 0;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }



        &_has-icon {
            padding-right: 46px;
        }

        &_uppercase {
          text-transform: uppercase;
        }
    }

    &__label {
        position: absolute;
        top: 1.3rem;
        left: 1.2rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #707578;
        white-space: nowrap;
        transition: transform .2s, font-size .2s, color .2s, opacity .2s;
        pointer-events: none;
        margin: 0;
        user-select: none;
        max-width: calc(100% - 1.3rem);
        overflow: hidden;
        text-overflow: ellipsis;

        &_active,
        .input-control__input:focus + & {
            font-size: 1rem;
            color: $text;
            transform: translate(0, -.75rem);
        }

        .input-control__input:disabled + & {
            opacity: .7;
        }

        .input-control_error &,
        .input-control_error &_active,
        .input-control_error .input-control__input:focus + & {
            color: $red;
        }
    }

    &__label-asterisk {
        color: $red;
    }

    &__message {
        position: absolute;
        left: 0;
        bottom: -1.6rem;
        padding-left: 1rem;
        font-size: 1rem;
        font-weight: 500;
        color: $text;

        .input-control_error & {
            color: $red;
        }

        .input-control_disabled & {
            opacity: 0.7;
        }
    }

    &__bottom-border {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $text-light;
        transition: height .25s ease, background-color .2s ease, opacity .2s ease;
        position: absolute;
        left: 0;
        bottom: 0;

        .input-control_disabled & {
            opacity: 0;
        }

        .input-control__input:focus ~ & {
            height: 2px;
            background-color: $text;
        }

        .input-control_error &,
        .input-control_error .input-control__input:focus ~ & {
            background-color: red;
        }
    }

    &__button {
        position: absolute;
        top: 7px;
        right: 2px;
        border: 0;
        background-color: $transparent;
        color: $text-light;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 3px;
        transition: color .15s ease, background-color .08s ease, box-shadow .2s ease, opacity .15s ease, transform .15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: -18px 0 12px -8px $form-control-background;

        &:hover,
        &:focus-visible {
            color: $text;
        }

        &:active {
            background-color: darken($form-control-background, 3%);
        }

        &_hidden {
            opacity: 0;
            transform: scale(.1);
        }

        .input-control__input:focus ~ &  {
            box-shadow: -18px 0 12px -8px rgba($form-control-background, 0);
        }

        .input-control_disabled & {
            opacity: .7;
            pointer-events: none;
        }

        .input-control__input:autofill ~ & {
            box-shadow: none;
        }

        .input-control_error & {
            color: $red-light;

            &:hover,
            &:focus-visible {
                color: $red;
            }

            &:active {
                background-color: darken(#FEF6F6, 3%);
            }
        }

        & + & {
            right: 34px;
        }
    }

    &__button-icon {
        width: 24px;
        height: 24px;

        &_h-mirrored {
            transform: scale(-1);
        }
    }

    &__state-icon {
        position: absolute;
        top: 16px;
        right: 12px;
        pointer-events: none;
        width: 24px;
        height: 24px;
        box-shadow: -19px 7px 10px 0 #f9f9f9;
        transition: opacity .15s ease, transform .15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        opacity: 0;
        transform: scale(.1);

        &_shown {
            opacity: 1;
            transform: scale(1);
        }

        &_rotated {
            svg {
                animation: rotate 1s linear infinite;
            }
        }

        &_success {
            color: $olive;
        }

        .input-control__input:focus ~ &  {
            box-shadow: -18px 0 12px -8px rgba($form-control-background, 0);
        }

        .input-control__input:autofill ~ & {
            box-shadow: none;
        }
    }
}
