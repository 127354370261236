@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/arimo-v27-latin-regular.eot'); // IE9 Compat Modes
  src: local(''),
       url('../../fonts/arimo-v27-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../../fonts/arimo-v27-latin-regular.woff2') format('woff2'), // Super Modern Browsers
       url('../../fonts/arimo-v27-latin-regular.woff') format('woff'), // Modern Browsers
       url('../../fonts/arimo-v27-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
       url('../../fonts/arimo-v27-latin-regular.svg#Arimo') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/arimo-v27-latin-500.eot'); // IE9 Compat Modes
  src: local(''),
       url('../../fonts/arimo-v27-latin-500.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../../fonts/arimo-v27-latin-500.woff2') format('woff2'), // Super Modern Browsers
       url('../../fonts/arimo-v27-latin-500.woff') format('woff'), // Modern Browsers
       url('../../fonts/arimo-v27-latin-500.ttf') format('truetype'), // Safari, Android, iOS
       url('../../fonts/arimo-v27-latin-500.svg#Arimo') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../fonts/arimo-v27-latin-600.eot'); // IE9 Compat Modes
  src: local(''),
       url('../../fonts/arimo-v27-latin-600.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../../fonts/arimo-v27-latin-600.woff2') format('woff2'), // Super Modern Browsers
       url('../../fonts/arimo-v27-latin-600.woff') format('woff'), // Modern Browsers
       url('../../fonts/arimo-v27-latin-600.ttf') format('truetype'), // Safari, Android, iOS
       url('../../fonts/arimo-v27-latin-600.svg#Arimo') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/arimo-v27-latin-700.eot'); // IE9 Compat Modes
  src: local(''),
       url('../../fonts/arimo-v27-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../../fonts/arimo-v27-latin-700.woff2') format('woff2'), // Super Modern Browsers
       url('../../fonts/arimo-v27-latin-700.woff') format('woff'), // Modern Browsers
       url('../../fonts/arimo-v27-latin-700.ttf') format('truetype'), // Safari, Android, iOS
       url('../../fonts/arimo-v27-latin-700.svg#Arimo') format('svg'); // Legacy iOS
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../fonts/oswald-v49-latin-700.eot'); // IE9 Compat Modes
  src: local(''),
       url('../../fonts/oswald-v49-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../../fonts/oswald-v49-latin-700.woff2') format('woff2'), // Super Modern Browsers
       url('../../fonts/oswald-v49-latin-700.woff') format('woff'), // Modern Browsers
       url('../../fonts/oswald-v49-latin-700.ttf') format('truetype'), // Safari, Android, iOS
       url('../../fonts/oswald-v49-latin-700.svg#Arimo') format('svg'); // Legacy iOS
}
