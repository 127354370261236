
            @import "src/assets/scss/vars/_base.scss";
          
.nav-switcher {
  display: flex;
  flex-direction: column;
  transition: padding-top .2s ease;
  padding-top: 1rem;

  &_no-one-active {
    padding-top: 20rem;
    
    @media (max-width: $lg) {
      padding-top: 5rem;
    }
  }

  &__item {
    
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.8rem;
    line-height: 1.1429;
    padding: 1rem 1rem 1rem 0;
    font-weight: 700;

    @media (max-width: $lg) {
      justify-content: center;
      margin-top: 2rem;
      border-radius: 9px;
      border: 1px solid #eee;
    }

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
    }

    .nav-switcher__item_active & {
      @media (max-width: $lg) {
        background-color: #fafafa;
      }
    }
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform .2s ease;
    transform: rotateZ(-180deg);
    margin-left: 1.5rem;

    .nav-switcher__item_active & {
      transform: rotateZ(-90deg);
    }

    svg {
      fill: none;
      stroke: #000;
    }
  }

  &__body {
    opacity: 0;
    transform-origin: center bottom;
    transform: scale(.75);
    transition: opacity .2s ease, transform .2s ease-in-out;

    .nav-switcher__item_active & {
      opacity: 1;
      transform: scale(1);
    }
  }
}
