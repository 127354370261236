
            @import "src/assets/scss/vars/_base.scss";
          
$header-height: 7rem;

.app-header {
  height: $header-height;
  background-color: #000;
  color: #fff;

  &__box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $header-height;

    @media (max-width: $lg) {
      position: relative;
    }
  }

  &__logo {
    width: 9.8rem;
    height: 3.5rem;
    overflow: hidden;
    text-indent: -1200px;
    transition: background-color .2s ease;
    margin-right: auto;

    &:hover {
      background-color: rgba(#fff, .15);
    }

    @media (max-width: $lg) {
      margin-left: auto;
    }
  }

  &__user-menu {
    
  }
}
