
            @import "src/assets/scss/vars/_base.scss";
          
.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: -.5rem;
  padding: .5rem .9rem .5rem .5rem;
  transition: background-color .2s ease;
  color: #000;
  border-radius: 0.5rem;
  height: 3rem;

  @media (max-width: $lg) {
    top: 22px;
    left: 40px;
    color: #fff;
    background-color: rgba(#fff, .3);
  }

  @media (max-width: $sm) {
    left: 16px;
  }

  .properties__form & {
    @media (max-width: $lg) {
      top: -90px;
      left: 0;
      color: #000;
      background-color: rgba(#fff, 0);
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
    background-color: #f1f1f1;
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: .6rem;
  }

  &__text {
    font-size: 1.05rem;
    font-weight: 400;
    line-height: 1.1429;
  }
}
