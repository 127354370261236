
            @import "src/assets/scss/vars/_base.scss";
          
.guest-message-form {
  &__label {
    font-size: 1.4rem;
    line-height: 1.14286;
    font-weight: 700;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
  }

  &__toggle-box {
    font-size: 1rem;
    color: #969696;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    margin-left: auto;
  }

  &__toggle {
    margin-left: 1rem;
  }

  &__symbol-counter {
    margin-bottom: 0.5rem;
  }
}
