
            @import "src/assets/scss/vars/_base.scss";
          
.sign-in {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $lg) {
      margin-top: -17.8rem;
      position: relative;
      z-index: 1;
    }
  }

  &__logo {
    width: 5rem;
    height: 5rem;
    margin-bottom: 5.8rem;

    @media (max-width: $lg) {
      color: #fff;
      margin-bottom: 11rem;
      filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 1));
    }
  }

  &__form {
    margin-bottom: 4rem;
  }

  &__footer {
    margin-top: auto;
  }
}
