
            @import "src/assets/scss/vars/_base.scss";
          
.properties {
  &__box {
    transition: padding-left .15s ease;

    &_pl {
      padding-left: 4rem;

      @media (max-width: $lg) {
        padding-left: 0;
      }
    }
  }

  &__device-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__device-card-box {
    margin-right: 4.65rem;
    margin-bottom: 2.65rem;

    &_loading {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__device-card {
    width: 18rem;
    height: 11.15rem;
    border: 1px solid #969696;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #333232;

    &_link-button {
      background: rgba(#000, 0);
      color: #50b49b;
      border-color: #50b49b;
    }
  }

  &__device-unlink {
    display: inline-block;
    color: #8e0505;
    margin-top: 0.5rem;
  }

  &__confirm-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 30px 15px;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 3000;
    pointer-events: none;

    &_shown {
      pointer-events: all;
      overflow-y: scroll;
    }
  }

  &__confirm {
    padding: 3.3rem 9.1rem;
    background-color: #fff;
    box-shadow: 0px .15rem .3rem rgba(0, 0, 0, .16);
    opacity: 0;
    transform-origin: top center;
    transform: perspective(600px) translateY(500px) rotateX(-45deg) scale(.6);
    transition: transform .25s ease-in-out, opacity .25s ease;
    margin: auto;
    width: 42.85rem;

    .properties__confirm-box_shown & {
      opacity: 1;
      transform: perspective(600px) translateY(0) rotateX(0) scale(1);
    }
  }

  &__confirm-description {
    text-align: center;
  }

  &__form {
    position: relative;
    border-top: 1px solid rgba(#707070, .18);
    padding-top: 2.25rem;
  }

  &__back-link {
    top: -6.2rem;
    left: -7rem;
  }

  &__address {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.45;

    .properties__box_pl & {
      @media (max-width: $lg) {
        padding-left: 6rem;
      }
    }
  }

  &__edit-button-box {
    vertical-align: top;
    width: 1px;
    height: 1px;
    display: inline-block;
    position: relative;
    
    @media (max-width: $lg) {
      width: 70px;
    }
  }

  &__edit-button {
    position: absolute;
    top: -.72rem;
    left: .2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 0.6rem 0.8rem;
    font-family: 'Arimo', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    color: #919191;
    background-color: rgba(#f2f2f2, 0);
    border-radius: .5rem;
    transition: background-color .2s ease, color .2s ease;

    &:hover {
      color: $text;
    }
  }

  &__edit-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.6rem;
  }
}
