@import '../utils/mixins';
@import './color-scheme';
@import '../utils/sizes';

$main-bg: #FDFFFF;
$main-text-color: $text;

$link_color           : $text;
$link-decoration      : none;
$link-hover-color     : $blue;
$link-hover-decoration: underline;
$table-bg             : #FDFFFF;

$transparent: rgba($main-bg, 0);
