
            @import "src/assets/scss/vars/_base.scss";
          
.svg-icon {
    display: inline-block;

    &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        fill: currentColor;
    }

    &__element {
        &_blue {
            fill: $blue;
        }

        &_white {
            fill: #fff;
        }
    }
}
