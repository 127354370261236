
            @import "src/assets/scss/vars/_base.scss";
          
.section-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
        flex: none;
        color: #969696;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 400;
    }

    &:after {
        content: '';
        display: block;
        height: 1px;
        width: 10px;
        background-color: rgba(#707070, .16);
        flex-grow: 1;
        margin-left: 1.4rem;
    }
}
