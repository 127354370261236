
            @import "src/assets/scss/vars/_base.scss";
          
.loader-placeholder {
    min-height: 40rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &_small {
        min-height: 0;
        padding: 1rem 0;
    }

    &__spinner {
        width: 2rem;
        height: 2rem;
        color: $text-light;
        animation: rotate 1s linear infinite;
    }
}
