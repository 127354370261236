
            @import "src/assets/scss/vars/_base.scss";
          
.textarea-control {
    margin-bottom: 8px;
    padding-bottom: 20px;

    &__box {
        position: relative;
        border-radius: 6px 6px 0 0;
        padding: 25px 0 0;
        transition: background-color 0.2s;
        background-color: $form-control-background;
        display: block;
        margin: 0;
        cursor: text;

        &:before, // normal control background
        &:after { // error control background
            content: '';
            position: absolute;
            top: 25px;
            left: 0;
            right: 0;
            height: 10px;
            transition: opacity 0.2s;
        }

        &:after { // error control background
            background: linear-gradient(to top, rgba(#FEF6F6, 0) 0, #FEF6F6 10px, #FEF6F6 100%);
            opacity: 0;
        }

        .textarea-control_error & {
            border-color: $red;
            background-color: #FEF6F6;

            &:before { // normal control background
                opacity: 0;
            }

            &:after { // error control background
                opacity: 1;
            }
        }

        &:focus-within {
            &:before, // normal control background
            &:after { // error control background
                opacity: 0;
            }
        }
    }

    &__input {
        display: block;
        width: 100%;
        min-height: 56px;
        outline: none;
        padding: 5px 15px 10px;
        border: 0;
        font-family: 'Arimo', Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 500;
        background-color: rgba($form-control-background, 0);
        transition: color .2s;

        &:disabled {
            cursor: not-allowed;
            border-color: rgba(#707578, 0);
            color: #707578;
        }

        &::-webkit-contacts-auto-fill-button,
        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            height: 0;
            width: 0;
            margin: 0;
        }

        &_sm {
            height: 64px;
        }
    }

    &__label {
        position: absolute;
        z-index: 1;
        top: 17px;
        left: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #707578;
        white-space: nowrap;
        transition: transform .2s, font-size .2s, color .2s, opacity .2s;
        pointer-events: none;
        margin: 0;
        padding: 2px 0;
        user-select: none;
        right: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        text-align: left;

        &_active,
        .textarea-control__input:focus + & {
            font-size: 12px;
            color: $text;
            transform: translate(0, -10px);
        }

        .textarea-control__input:disabled + & {
        opacity: .7;
        }

        .textarea-control_error &,
        .textarea-control_error &_active,
        .textarea-control_error .textarea-control__input:focus + & {
            color: $red;
        }
    }

    &__label-asterisk {
        color: $red;
    }

    &__message {
        position: absolute;
        left: 0;
        bottom: -16px;
        padding-left: 14px;
        font-size: 12px;
        font-weight: 500;
        color: $text;
        user-select: none;

        .textarea-control_error & {
            color: $red;
        }

        .textarea-control_disabled & {
            opacity: 0.7;
        }

        &_align-right {
            left: auto;
            right: 0;
            padding-right: 14px;
        }
    }

    &__bottom-border {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #707578;
        transition: height .25s ease, background-color .2s ease, opacity .2s ease;
        position: absolute;
        left: 0;
        bottom: 0;

        .textarea-control_disabled & {
            opacity: 0;
        }

        .textarea-control__input:focus ~ & {
            height: 2px;
            background-color: $text;
        }

        .textarea-control_error &,
        .textarea-control_error .textarea-control__input:focus ~ & {
            background-color: red;
        }
    }

}
