#freshworks-container {
    #launcher-frame {
        max-width: 132px !important;

        @media (max-width: $lg) {
          right: 10px !important;
          bottom: 6px !important;
        }
    }
}
