
            @import "src/assets/scss/vars/_base.scss";
          
.user-profile {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media (max-width: $lg) {
    flex-direction: column;
  }

  &__nav {
    width: 30.3rem;
    flex: none;
    padding: 2rem 2rem 2rem 0;
    border-right: 1px solid #e1e1e1;

    @media (max-width: $lg) {
      width: auto;
      padding: 0 0 0 1rem;
      border-right: 0;
      border-left: 4px solid #e1e1e1;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 0.6rem;
    }
  }

  &__link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 1rem 1rem;
    transition: background-color .2s ease;
    border-radius: 4px;

    @media (max-width: $lg) {
      display: flex;
    }

    &_active {
      @media (max-width: $lg) {
        background-color: #f5f5f5;
      }
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: underline;
    }
  }

  &__link-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1rem;
    opacity: 0;
    transform: rotate(180deg) translateX(.5rem);
    transition: opacity .2s ease, transform .2s ease-in-out;

    .user-profile__link_active & {
      opacity: 1;
      transform: rotate(180deg) translateX(0);
    }
  }

  &__link-icon {

  }

  &__body {
    flex-grow: 1;
    padding: 2rem 0rem 2rem 6rem;

    @media (max-width: $lg) {
      padding: 2rem 0;
    }
  }
}
