
            @import "src/assets/scss/vars/_base.scss";
          
.error-message {
  color: $red;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff1f1;
    border-radius: 1rem;
    padding: 4rem 6rem;
    border: 1px solid #ffdbdb;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    margin-bottom: 2rem;
  }

  &__text {

  }
}
