
            @import "src/assets/scss/vars/_base.scss";
          
.user-avatar {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $lg) {
    display: flex;
  }

  &__preview {
    width: 8.4rem;
    height: 8.4rem;
    border-radius: 4.2rem;
    border: .5rem solid #fff;
    box-shadow: 0 .15rem .6rem rgba(#000, .16);
    cursor: pointer;
    background-color: #f7f7f7;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__button-box {
    padding-top: 4.7rem;
    display: flex;
    flex-direction: row;

    @media (max-width: $lg) {
      width: 100%;
      justify-content: center;
    }
  }

  &__button {
    width: 7rem;

    @media (max-width: $lg) {
      width: 12rem;
    }

    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  &__message {
    margin-top: -3rem;
    margin-bottom: 3rem;

    @media (max-width: $lg) {
      text-align: center;
    }
  }
}
