
            @import "src/assets/scss/vars/_base.scss";
          
.welcome-form {
  &__preview-box {
    display: flex;
    flex-direction: row;
    margin: 1.85rem 0 2.85rem;
    align-items: flex-end;
  }

  &__preview {
    border: .25rem solid #fff;
    background-color: #efefef;
    box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 1.5rem;
    position: relative;

    &_background {
      width: 20.4444rem;
      height: 11.5rem;
    }

    &_media {
      width: 4.98rem;
      height: 8.94rem;
      border-width: .1rem;
      border-radius: .45rem;
    }
  }

  &__label-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 0.15rem;
  }

  &__label {
    margin-left: auto;
    font-size: 0.75rem;
    color: #969696;
    font-weight: 400;
  }

  &__media-box {
    margin-left: 1.5rem;
    border-radius: .45rem;
    overflow: hidden;
    background-color: #fff;
    border-width: .1rem;
    box-shadow: 0 .15rem .3rem rgba(0, 0, 0, .16);
    font-size: 0;
    position: relative;
  }

  &__media-preview {
    width: 4.98rem * 2;
    height: 8.94rem * 2;
  }

  &__uploading-progress {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: opacity .2s ease;
    opacity: 0;
    background-color: rgba(#c9ffed, 50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 12%;

    &_active {
      pointer-events: all;
      opacity: 1;
    }

    .welcome-form__preview_background & {
      padding-bottom: 1%;
    }
  }

  &__progress-bar {
    transition: width .1s ease;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(270deg, #51B997 0%, rgba(81, 185, 151, 0.4) 100%);
    border-right: 1px solid #00a49a;
  }

  &__progress-value {
    font-style: normal;
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 0.95rem;
    font-weight: 700;
    text-shadow: -1px -1px 0 #afd8ca, 1px -1px 0 #afd8ca, -1px 1px 0 #afd8ca, 1px 1px 0 #afd8ca; 
  }
}
